import { AuthService }       from 'aurelia-auth';
import { Aurelia, inject }   from 'aurelia-framework';
import { PLATFORM }          from 'aurelia-pal';
import { AureliaAuthConfig } from 'resources/configs/aurelia-auth-config';
import { AppContainer }      from 'resources/services/app-container';
import { AuthenticatedUser } from 'resources/services/authenticated-user';
import { CustomHttpClient }  from 'resources/services/custom-http-client';

@inject(AppContainer, Aurelia, AuthenticatedUser, AuthService, CustomHttpClient)
export class PageSidebarUserMenu {
    userDisplayName;

    eventListeners = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param aurelia
     * @param authenticatedUser
     * @param authService
     * @param httpClient
     */
    constructor(appContainer, aurelia, authenticatedUser, authService, httpClient) {
        this.appContainer      = appContainer;
        this.aurelia           = aurelia;
        this.authenticatedUser = authenticatedUser;
        this.authService       = authService;
        this.httpClient        = httpClient;
    }

    /**
     * Handles bind event
     *
     * @returns {Promise<any[]>}
     */
    bind() {
        return this.fetchFromAuthenticatedUser();
    }

    /**
     * Handles attached event
     */
    attached() {
        this.subscribeEventListeners();
    }

    /**
     * Subscribes event listeners
     */
    subscribeEventListeners() {
        // subscribes `lot-intervention-selected` event
        this.eventListeners.push(
            this.appContainer.eventAggregator.subscribe('lot-intervention-selected', () => {
                this.fetchFromAuthenticatedUser();
            }),
        );
    }

    /**
     * Fetches data from authenticated user
     */
    fetchFromAuthenticatedUser() {
        this.userDisplayName = this.appContainer.authenticatedUser.getDisplayName();
    }

    /**
     * Logs out the logged user
     */
    logout() {
        this.httpClient.get('auth/logout')
            .then(() => this.authService.logout(AureliaAuthConfig.logoutRedirect)
                .then(() => this.aurelia.setRoot(PLATFORM.moduleName('app-offline')))
                .catch(() => console.error('Error logging out')),
            );
    }

}
