import { customElement, inject } from 'aurelia-framework';
import { BindingSignaler }       from 'aurelia-templating-resources';
import { AppContainer }          from 'resources/services/app-container';
import { LanguagesRepository }   from 'modules/administration/languages/services/repository';

@inject(BindingSignaler, AppContainer, LanguagesRepository)
@customElement('language-switch-dropdown')
export class LanguageSwitchDropdown {

    languages = [];

    /**
     * Constructor
     *
     * @param signaler
     * @param appContainer
     * @param repository
     */
    constructor(signaler, appContainer, repository) {
        this.signaler        = signaler;
        this.storage         = appContainer.localStorage;
        this.eventAggregator = appContainer.eventAggregator;
        this.i18n            = appContainer.i18n;
        this.repository      = repository;
    }

    /**
     * Handles attached event
     */
    attached() {
        this.fetchData();
    }

    /**
     * Changes application language
     *
     * @param locale
     */
    changeLocale(locale) {
        // saves defined locale in storage
        this.storage.set('locale', locale);

        // sets application locale
        this.i18n.setLocale(locale);

        // publishes `locale-changed` event
        this.eventAggregator.publish('locale-changed');
        this.signaler.signal('locale-changed');
    }

    /**
     * Returns flag
     *
     * @returns {string}
     */
    get flag() {
        let flag = 'pt.png';
        this.languages.forEach((language) => {
            if (language.locale === this.appLocale) {
                flag = language.flag;
            }
        });

        return flag;
    }

    /**
     * Returns app locale
     *
     * @returns {*}
     */
    get appLocale() {
        return this.storage.get('locale');
    }

    /**
     * Fetches data from server
     */
    fetchData() {
        this.repository.allLocales().then((languages) => this.languages.splice(0, this.languages.length, ...languages));
    }
}
