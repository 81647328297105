import { inject }                 from 'aurelia-framework';
import { Router }                 from 'aurelia-router';
import { MaintenancesRepository } from 'modules/administration/maintenances/services/repository';
import { UsersRepository }        from 'modules/administration/users/services/repository';
import { AuthenticatedUser }      from 'resources/services/authenticated-user';
import { LaravelEchoService }     from 'resources/services/laravel-echo-service';

@inject(Router, LaravelEchoService, AuthenticatedUser, UsersRepository, MaintenancesRepository)
export class MaintenancesMediaList {

    dropdownMenu;
    allMaintenancesAnchor;

    eventListeners = [];
    maintenances   = [];

    dateFormat = 'DD/MM/YYYY @ HH:mm';

    ongoingMaintenance;

    /**
     * Constructor
     *
     * @param router
     * @param laravelEchoService
     * @param authenticatedUser
     * @param usersRepository
     * @param maintenancesRepository
     */
    constructor(router, laravelEchoService, authenticatedUser, usersRepository, maintenancesRepository) {
        this.router                 = router;
        this.laravelEchoService     = laravelEchoService;
        this.authenticatedUser      = authenticatedUser;
        this.usersRepository        = usersRepository;
        this.maintenancesRepository = maintenancesRepository;
    }

    /**
     * Handles bind event
     *
     * @returns {Promise<any[]>}
     */
    bind() {
        return this.fetchData();
    }

    /**
     * Handles attached event
     */
    attached() {
        this.subscribeMaintenancesChannel();
        this.subscribeEventListeners();
    }

    /**
     * Fetches data from remote source
     *
     * @returns {*}
     */
    fetchData() {
        return Promise.all([
            this.maintenancesRepository.scheduled().then((maintenances) => {
                if (isArray(maintenances)) {
                    this.maintenances = maintenances;
                }

                return maintenances;
            }),
            this.maintenancesRepository.ongoing().then((ongoingMaintenance) => this.ongoingMaintenance = ongoingMaintenance),
        ]);
    }

    /**
     * Subscribes maintenances channel
     */
    subscribeMaintenancesChannel() {
        if (this.laravelEchoService.instantiated) {
            window.Echo.channel('Ascendi.RumoQ.Events')
                .listen('MaintenancesUpdated', (event) => {
                    let previousCount = this.maintenances.length;

                    this.maintenances.splice(0, this.maintenances.length, ...event.maintenances.scheduled);
                    this.ongoingMaintenance = event.maintenances.ongoing;

                    // TODO - THIS IS HERE ONLY FOR TESTING
                    if (this.maintenances.length > previousCount) {
                        let audio = new Audio('/assets/audio/notification.mp3');

                        return audio.play();
                    }
                });
        }
    }

    /**
     * Navigates to a given maintenance
     *
     * @param maintenance
     */
    navigateToMaintenance(maintenance) {
        return this.router.navigateToRoute('administration.maintenances.view', { id: maintenance.id });
    }

    /**
     * Subscribes event listeners
     */
    subscribeEventListeners() {
        // subscribes `all-maintenances-anchor click event` in order to close maintenances dropdown
        $(this.allMaintenancesAnchor).on('click', () => $(this.dropdownMenu).removeClass('show'));
    }

    /**
     * Disposes event listeners
     */
    disposeEventListeners() {
        while (this.eventListeners.length) {
            this.eventListeners.pop().dispose();
        }
    }

    toggleMaintenance() {
        return this.maintenancesRepository.toggleMaintenance().then((ongoingMaintenance) => {
            this.ongoingMaintenance = ongoingMaintenance;
        });
    }

}
