import environment from '../../../../config/environment.json';

export class PageFooter {

    /**
     * Constructor
     */
    constructor() {
        this.environment = environment;
    }

}
