import { customElement, inject } from 'aurelia-framework';
import { I18N }                  from 'aurelia-i18n';
import { AppContainer }          from 'resources/services/app-container';

@inject(AppContainer, I18N)
@customElement('lot-intervention-selection')
export class LotInterventionSelection {

    lotInterventionName;
    entityTeamName;
    lotInterventionTitle;

    eventListeners = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param i18N
     */
    constructor(appContainer, i18N) {
        this.appContainer = appContainer;
        this.i18N         = i18N;
    }

    /**
     * Handles bind event
     *
     * @returns {Promise<any[]>}
     */
    bind() {
        return this.fetchFromAuthenticatedUser();
    }

    /**
     * Handles attached event
     */
    attached() {
        this.subscribeEventListeners();
    }

    /**
     * Subscribes event listeners
     */
    subscribeEventListeners() {
        // subscribes `lot-intervention-selected` event
        this.eventListeners.push(
            this.appContainer.eventAggregator.subscribe('lot-intervention-selected', () => {
                this.fetchFromAuthenticatedUser();
            }),
        );
    }

    /**
     * Fetches data from authenticated user
     */
    fetchFromAuthenticatedUser() {
        this.lotInterventionName = this.appContainer.authenticatedUser.getSelectedLotInterventionDisplayName(true);
        this.entityTeamName      = this.appContainer.authenticatedUser.getSelectedEntityTeamDisplayName(true);

        this.lotInterventionTitle = this.entityTeamName ? this.lotInterventionName + ' [' + this.entityTeamName + ']' : this.lotInterventionName;
    }

    /**
     * Redirects to the lot/intervention selection page
     *
     * @return {*}
     */
    selectLotIntervention() {
        return this.appContainer.router.navigateToRoute('concessions.lot-intervention-selection.index');
    }
}
